import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CreditData, DocumentsModel, InfoBorrower } from 'src/app/core';
import { esNIE } from 'src/app/core/extensions/esNIE';
import { getUploadAutoDoc, getUploadIncomeDoc } from 'src/app/core/extensions/uploadDocs';
import { SecondaryReason } from 'src/app/core/models/configs/secondaryReason';
import { UpdateStatus } from './../../../../../core/extensions/updateStatus';
import { CotitularModel } from './../../../../../core/models/borrower/borrower';
import { UserService } from './../../../../../core/services/user/user.service';


@Component({
    selector: 'app-dni',
    templateUrl: './dni.component.html',
    styleUrls: ['./dni.component.scss'],
    standalone: false
})
export class DniComponent implements OnInit {

  _idParam: string;
  _creditData: CreditData;
  _infoBorrower: InfoBorrower;
  _secondaryReason: SecondaryReason;
  _cotitular: CotitularModel;
  public expansionPanels: any;
  public docsFiltered: DocumentsModel[];
  public docsUploaded: DocumentsModel[];
  public userDocs: DocumentsModel[];
  public nextStep: any;
  public titleNie: string;
  public nonAffiliatetitleNie: string;

  public uploading: boolean = false;
  public file: string;
  public textInput: string;
  public documentosCotNIE: boolean = false;
  public documentosNIE: boolean = false;
  public userType: string;

  //Booleans para confirmar qué documentos se han subido (en tiempo real)
  public dniFrontUploaded: boolean = false;
  public nieFrontUploaded: boolean = false;
  public dniBackUploaded: boolean = false;
  public nieBackUploaded: boolean = false;
  public dniFrontCoTUploaded: boolean = false;
  public nieFrontCotUploaded: boolean = false;
  public dniBackCotUploaded: boolean = false;
  public nieBackCotUploaded: boolean = false;
  public passportUploaded: boolean = false;
  public passportCotUploaded: boolean = false;
  public incomeProofUploaded: boolean = false;
  public irpfUploaded: boolean = false;
  public incomeProofCotUploaded: boolean = false;
  public irpfCotUploaded: boolean = false;
  public billUploaded: boolean = false;
  public vehicleTechnicalUploaded: boolean = false;
  public circulationPermitUploaded: boolean = false;
  public ibanUploaded: boolean = false;
  public idCardFrontUploaded: boolean = false;
  public idCardBackUploaded: boolean = false;
  public driverFrontUploaded: boolean = false;
  public driverBackUploaded: boolean = false;
  
  public moreOtherDocs: boolean = false;
 
  public disabledButton: boolean = true;
  public ibanActive: boolean = false;
  public billActive: boolean;
  public panelsResults: any[];
  public paymentDocIncome: boolean = false;
  public paymentDocIrpf: boolean = false;
  public titleStatus: string;
  public alertMessage: string;
  public docsFilteredByStatus: DocumentsModel[] = [];


  @Input() idParam: string;
  @Input() financingCode!: string;
  @Input() disableChangeStatus?: boolean
  @Input() set creditData(value: CreditData){
    if(!value){
      return
    }else{
    this._creditData = value;
    if(this._creditData.corporationName == "DORSIA CENTRAL"){
      this.ibanActive = true;
      }else{
        this.ibanActive = false;
      }
    }
  }
  @Input() set infoBorrower(value: InfoBorrower){
    if(!value){
      return
    }else{
      this._infoBorrower = value;
      if(esNIE(this._infoBorrower.dni)){
        this.documentosNIE = true;
      } else {
        this.documentosNIE = false;
      }
    }
  }

  @Input() set secondaryReason(val: SecondaryReason){
    if(!val){
      return
    }else{
      this._secondaryReason = val[0];
      if(this._creditData && this._secondaryReason){
          this.billActive = true;
      }
      if(this._secondaryReason.primaryReason == 'Salud'){
        if(getUploadIncomeDoc(this._infoBorrower, this._secondaryReason, this._creditData)){
          this.paymentDocIrpf = true;
        }else{
          this.paymentDocIncome = true;
        }
      }

    }
    
  }
  @Input() set cotitular(val: CotitularModel){
    if(!val){
      return
    }else{
      this._cotitular = val;
      if(esNIE(this._cotitular.dni)){
        this.documentosCotNIE = true;
      } else {
        this.documentosCotNIE = false;
      }
    }
  }


  constructor(private updateStatus: UpdateStatus, private router: Router, private userService: UserService) { 
    /* this.spinnerService.hideLoading() */
   
  }
  
 
  ngOnInit(): void {
    this.userService.getUserDocuments(localStorage.getItem('userBorrowerId')).subscribe(
      res => {
        this.userDocs = res["hydra:member"]
        this.docsFiltered = this.userDocs.filter(element => element["@loan"] == `/api/creditos/${localStorage.getItem('idBorrower')}`)
        this.filterStatus(this.docsFiltered)
			  this.docsFilteredByStatus.forEach(element => {
        if(element.type == 'iban'){
          this.ibanUploaded = true;
          this.titleStatus = 'iban'
        }else if(element.type == 'dniFotoFrontal'){
          element.status <= 2 ? this.dniFrontUploaded = true : this.dniFrontUploaded = false;
          this.titleStatus = 'la parte delantera del DNI'
        }else if(element.type == 'nieFotoFrontal'){
          element.status <= 2 ? this.nieFrontUploaded = true : this.nieFrontUploaded = false;
          this.titleStatus = 'la parte delantera del NIE'
        }else if(element.type == 'dniFotoTrasera'){
          element.status <= 2 ? this.dniBackUploaded = true : this.dniBackUploaded = false;
          this.titleStatus = 'la parte trasera del DNI'
        }else if(element.type == 'nieFotoTrasera'){
          element.status <= 2 ? this.nieBackUploaded = true : this.nieBackUploaded = false;
          this.titleStatus = 'la parte delantera del NIE'
        }else if(element.type == 'pasaporte'){
          element.status <= 2 ? this.passportUploaded = true : this.passportUploaded = false;
          this.titleStatus = ' el pasaporte'
        }else if(element.type == 'justificanteCompra'){
          element.status <= 2 ? this.billUploaded = true : this.billUploaded = false;
          this.titleStatus = 'el justificante de compra'
        }else if(element.type == 'nomina'){
          element.status <= 2 ? this.incomeProofUploaded = true : this.incomeProofUploaded = false;
          this.titleStatus = 'la última nómina o prueba de ingresos'
        }else if(element.type == 'irpf'){
          element.status <= 2 ? this.irpfUploaded = true : this.irpfUploaded = false;
          this.titleStatus = 'el último IRPF'
        }else if(element.type == 'idCardFrontal'){
          element.status <= 2 ? this.idCardFrontUploaded = true : this.idCardFrontUploaded = false;
          this.titleStatus = 'la documentación'
        }else if(element.type == 'idCardTrasera'){
          element.status <= 2 ? this.idCardBackUploaded = true : this.idCardBackUploaded = false; 
          this.titleStatus = 'la documentación'
        }else if(element.type == 'driverFrontal'){
          element.status <= 2 ? this.driverFrontUploaded = true : this.driverFrontUploaded = false;
          this.titleStatus = 'la parte frontal del carnet de conducir'
        }else if(element.type == 'driverTrasera'){
          element.status <= 2 ? this.driverBackUploaded = true : this.driverBackUploaded = false;
          this.titleStatus = 'la parte trasera del carnet de conducir'
        }else if(element.type == 'fichaTecnica'){
          element.status <= 2 ? this.vehicleTechnicalUploaded = true : this.vehicleTechnicalUploaded = false;
          this.titleStatus = 'la ficha técnica del vehículo'
        }else if(element.type == 'permisoCirculacion'){
          element.status <= 2 ? this.circulationPermitUploaded = true : this.circulationPermitUploaded = false;
          this.titleStatus = 'el permiso de circulación'
        }else if(element.type == 'dniFotoFrontalCotitular'){
          element.status <= 2 ? this.dniFrontCoTUploaded = true : this.dniFrontCoTUploaded = false;
          this.titleStatus = 'la parte delantera del DNI del cotitular'
        }else if(element.type == 'dniFotoTraseraCotitular'){
          element.status <= 2 ? this.dniBackCotUploaded = true : this.dniBackCotUploaded = false;
          this.titleStatus = 'la parte trasera del DNI del cotitular'
        }else if(element.type == 'nieFotoFrontalCotitular'){
          element.status <= 2 ? this.nieFrontCotUploaded = true : this.nieFrontCotUploaded = false;
          this.titleStatus = 'la parte delantera del NIE del cotitular'
        }else if(element.type == 'nieFotoTraseraCotitular'){
          element.status <= 2 ? this.nieBackCotUploaded = true : this.nieBackCotUploaded = false;
          this.titleStatus = 'la parte trasera del NIE del cotitular'
        }else if(element.type == 'pasaporteCotitular'){
          element.status <= 2 ? this.passportCotUploaded = true : this.passportCotUploaded = false;
          this.titleStatus = 'el pasaporte del cotitular'
        }else if(element.type == 'nominaCotitular'){
          element.status <= 2 ? this.incomeProofCotUploaded = true : this.incomeProofCotUploaded = false;
          this.titleStatus = 'la última nomina del cotitular'
        }else if(element.type == 'irpfCotitular'){
          element.status <= 2 ? this.irpfCotUploaded = true : this.irpfCotUploaded = false;
          this.titleStatus = 'el último IRPF del cotitular'
        }
        if(element.status <= 2){
        }else if(element.status == 4){
          element['title'] = this.titleStatus
          element['alertMessage'] = `Rechazado: Ilegible. Vuelve a subir ${this.titleStatus}`;
        }else if(element.status == 5){
          element['title'] = this.titleStatus
          element['alertMessage'] = `Rechazado: Caducado. Vuelve a subir ${this.titleStatus}`;
        }else{
          element['title'] = this.titleStatus
          element['alertMessage'] = `Rechazado.El documento no es válido. Vuelve a subir ${this.titleStatus}`;
        }
        
      })
      
      this.expansionPanels = [
        {        
          title: `Parte delantera del DNI`,
          formName: 'dniFront',
          type: "dniFront",
          uploaded: this.dniFrontUploaded,// si esta subido pero tiene que aparecer que esta subido
          upload: false, // se acaba de subir para ver la previsualización
          active: !this.documentosNIE,
        },
        {      
          title: `Parte trasera del DNI`,
          formName: 'dniBack',
          type: "dniBack",
          uploaded: this.dniBackUploaded,
          upload: false,
          active: !this.documentosNIE,
        },
        {        
          title: `Parte delantera del NIE`,
          formName: 'nieFront',
          type: "nieFront",
          uploaded: this.nieFrontUploaded,// si esta subido pero tiene que aparecer que esta subido
          upload: false, // se acaba de subir para ver la previsualización
          active: this.documentosNIE,
        },
        {      
          title: `Parte trasera del NIE`,
          formName: 'nieBack',
          type: "nieBack",
          uploaded: this.nieBackUploaded,
          upload: false,
          active: this.documentosNIE,
        },
        {      
          title: `Parte delantera del DNI del cotitular`,
          formName: 'dniFrontCoborrower',
          type: "dniFrontCoborrower",
          uploaded: this.dniFrontCoTUploaded,
          upload: false, 
          active: !this.documentosCotNIE && this._creditData.cotitular,
        },
        {    
          title: `Parte trasera del DNI del cotitular`,
          formName: 'dniBackCoborrower',
          type: "dniBackCoborrower",
          uploaded: this.dniBackCotUploaded,
          upload: false,
          active: !this.documentosCotNIE && this._creditData.cotitular,
        },
        {     
          title: `Parte delantera del NIE del cotitular`,
          formName: 'nieFrontCoborrower',
          type: "nieFrontCoborrower",
          uploaded: this.nieFrontCotUploaded,
          upload: false, 
          active: this.documentosCotNIE && !this.passportCotUploaded && this._creditData.cotitular,
        },
        {
          title: `Parte trasera del NIE del cotitular`,
          formName: 'nieBackCoborrower',
          type: "nieBackCoborrower",
          uploaded: this.nieBackCotUploaded,
          upload: false,
          active: this.documentosCotNIE && !this.passportCotUploaded && this._creditData.cotitular,
        },
        {
          title: 'Subir foto del pasaporte',
          formName: 'passport',
          type: "passport",
          uploaded: this.passportUploaded,
          upload: false,
          active: this.documentosNIE && !this.nieBackUploaded && !this.nieFrontUploaded,
        },
        {  
          title: 'Subir foto del pasaporte del cotitular',
          formName: 'passportCoborrower',
          type: "passportCoborrower",
          uploaded: this.passportCotUploaded,
          upload: false,
          active: this.documentosCotNIE && !this.nieBackCotUploaded && !this.nieFrontCotUploaded && this._creditData.cotitular,
        },
        {
          title: "Adjunta la última nómina o prueba de ingresos",
          formName: 'incomeProof',
          type: 'incomeProof',
          uploaded: this.incomeProofUploaded,
          upload: false,
          active: !getUploadIncomeDoc(this._infoBorrower, this._secondaryReason, this._creditData) || this.paymentDocIncome,
        },
        {      
          title: "Adjunta la última nómina o prueba de ingresos del Cotitular",
          formName: 'incomeProofCoborrower',
          type: 'incomeProofCoborrower',
          uploaded: this.incomeProofCotUploaded,
          upload: false,
          active: this._creditData.cotitular && !getUploadIncomeDoc(this._infoBorrower, this._secondaryReason, this._creditData)
        },
        {       
          title: "Adjunta el último irpf",
          formName: 'irpf',
          type: 'irpf',
          uploaded: this.irpfUploaded,
          upload: false,
          active: getUploadIncomeDoc(this._infoBorrower, this._secondaryReason, this._creditData) || this.paymentDocIrpf,
        },
        {       
          title: "Adjunta el último irpf del Cotitular",
          formName: 'irpfCoborrower',
          type: 'irpfCoborrower',
          uploaded: this.irpfCotUploaded,
          upload: false,
          active: this._creditData.cotitular && getUploadIncomeDoc(this._infoBorrower, this._secondaryReason, this._creditData)
        },
        {    
          title: 'Subir foto de un justificante del iban',
          formName: 'iban',
          type: 'iban',
          uploaded: this.ibanUploaded,
          upload: false,
          active: this.ibanActive,
        },
        {  
          title: (this._creditData.router == 4 || this._creditData.router == 11 || this._creditData.infoCar) && getUploadAutoDoc(this._secondaryReason) ? 'Subir factura proforma de la compra del vehículo':'Subir un justificante del producto o servicio.',
          formName: 'bill',
          type: 'bill',
          uploaded: this.billUploaded,
          upload: false,
          active: this.billActive,
        },
        {   
          title: 'Subir permiso de circulación del vehículo',
          formName: 'vehicleCirculationPermit',
          type: 'vehicleCirculationPermit',
          uploaded: this.circulationPermitUploaded,
          upload: false,
          active: (this._creditData.router == 4 || this._creditData.router == 11 || this._creditData.infoCar) && getUploadAutoDoc(this._secondaryReason), 
        },
        { 
          title: 'Subir ficha técnica del vehículo',
          formName: 'vehicleTechnicalSheet',
          type: 'vehicleTechnicalSheet',
          uploaded: this.vehicleTechnicalUploaded,
          upload: false,
          active: (this._creditData.router == 4 || this._creditData.router == 11 || this._creditData.infoCar) && getUploadAutoDoc(this._secondaryReason), 
        },
        {       
          title: 'Otros documentos',
          formName: 'other',
          type: "other",
          uploaded: false,
          upload: false, 
          active: this.disableChangeStatus 
        }
      ]
      this.checkPanelsUploaded()
      }
    )
    /* this.spinnerService.hideLoading(); */
    this.userType = localStorage.getItem('userType');
    if(this.documentosNIE) {
      this.titleNie = 'el NIE y otro documento adicional de identidad';
      this.nonAffiliatetitleNie = 'NIE y otro documento adicional de identidad';
      this.textInput = 'NIE';
    } else {
      this.titleNie = 'el DNI';
      this.nonAffiliatetitleNie = 'DNI';
      this.textInput = 'DNI';
    }
    
  }
  
  uploadOtherDoc(){
    this.expansionPanels.push( {
      title: 'Subir más documentos',
      formName: 'other',
      type: "other",
      uploaded: false,
      upload: false, 
      buttonText: 'HACER FOTO/SELECCIONAR',
      active: this.disableChangeStatus 
    },)
  }

  receiveFile($event: string){
    this.file = $event;
    this.uploading = true;

  }
  
  checkPanelsUploaded(){
    const allUploaded: any[] = this.expansionPanels.filter((element) => !element.uploaded && element.active)
      if(allUploaded.length == 0){
        if(!this.disableChangeStatus){
          this.updateStatus.changeStatus(); 
        }else{
          /* this.router.navigate(['afiliado']) */
        }
        
      } 
  }

  checkPanels(value: any){
    const type = value.type
    const uploadedTrue = value.uploaded
    if(value){
      const newDoc = this.expansionPanels.find(item => item.type == type)
      const docIndex = this.expansionPanels.map(item => item.type).indexOf(type)
      newDoc.uploaded = uploadedTrue; 
      this.expansionPanels[docIndex] = newDoc;
      this.checkPanelsUploaded();
      if(uploadedTrue && type == 'other'){
        this.uploadOtherDoc();
        if(this.expansionPanels[this.expansionPanels.length - 2].title == 'Subir más documentos'){
          this.expansionPanels[this.expansionPanels.length - 2].uploaded = true
        }
      }
    }
  }

  filterStatus(arrayData: DocumentsModel[]) {
    const uniqueTypes = [...new Set(arrayData.map(item => item.type))];
    uniqueTypes.forEach(type => {
      const typesWithStatus = arrayData.filter(item => item.type === type);
      const correctType = typesWithStatus.reduce((prev, current) =>
        (prev.status < current.status) ? prev : current
      );
      this.docsFilteredByStatus.push(correctType);
    });
  }

}
